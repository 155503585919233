/***************************************************************
 * @NOTE: 医療機関詳細暫定移植で追加したファイルのため正規対応時に整理予定
 ***************************************************************/
/* eslint no-undef: 0 */
$(function () {
  $('a[href^="#"]').click(function () {
    const adjust = 0;
    const speed = 400;
    const href = $(this).attr('href');
    const target = $(href === '#' || href === '' ? 'html' : href);
    const position = target.offset().top + adjust;
    $('body,html').animate({ scrollTop: position }, speed, 'swing');
    return false;
  });
});
